import moment from "moment";
import {
  IsLocalStorageEmptyForApiCall,
  formatDateStringToISO,
  handleLang,
} from "../utils/Helper";
import { Config } from "../utils/config";

export const getQuiz = async ({ queryKey }) => {
  const { pageParam } = queryKey[1];
  const filter = queryKey[2];
  const selectedLanguage = handleLang();
  const langFromLocalStorage = IsLocalStorageEmptyForApiCall(selectedLanguage);
  return fetch(
    `${
      Config.BaseUrl
    }/quiz-questions?pagination[page]=${pageParam}&pagination[pageSize]=1&populate=*&locale=${
      filter.filter !== null ? filter.filter : langFromLocalStorage
    }&sort=ordering:ASC `
  ).then((res) => res.json());
};

export const getQuizInfo = async () => {
  const url = `${Config.BaseUrl}/sub-categories?filters[Sub_category_URL][$eq]=quiz&populate=*`;
  return fetch(url).then((res) => res.json());
};

export const getFBVideos = async () => {
  return fetch(
    `${Config.BaseUrl}/facebook-live-videos?pagination[pageSize]=5`
  ).then((res) => res.json());
};

export const getFeaturedFBVideos = async () => {
  return fetch(
    `${
      Config.BaseUrl
    }/featured-live-videos?pagination[pageSize]=1&filters[startDateTime][$lte]=${formatDateStringToISO(
      new Date()
    )}&filters[endDateTime][$gte]=${formatDateStringToISO(new Date())}&fields[0]=Facebook_url&fields[1]=Live_events_details` 
  ).then((res) => res.json()); 

};

export const upcoming_Events = async () => {
  return fetch(
    `${
      Config.BaseUrl
    }/calendars?pagination[pageSize]=3&filters[calendar_from_date][$gte]=${moment(
      new Date()
    ).format("YYYY-MM-DD")}&sort=calendar_from_date:ASC`
  ).then((res) => res.json());
};

export const getWhatsNew = async ({ queryKey }) => {
  const { filter } = queryKey[1];
  const selectedLanguage = handleLang();
  const langFromLocalStorage = IsLocalStorageEmptyForApiCall(selectedLanguage);
  return fetch(
    `${Config.BaseUrl}/whats-news?pagination[pageSize]=5&locale=${
      filter !== null ? filter : langFromLocalStorage
    }`
  ).then((res) => res.json());
};

export const featured_Contents = async () => {
  return fetch(
    `${Config.BaseUrl}/featurecontents?pagination[pageSize]=8&sort=Feature_Order:ASC`
  ).then((res) => res.json());
};
export const special_featured_Contents = async ({ queryKey }) => {
  const { filter } = queryKey[1];
  const selectedLanguage = handleLang();
  const langFromLocalStorage = IsLocalStorageEmptyForApiCall(selectedLanguage);
  return fetch(
    `${
      Config.BaseUrl
    }/india-special-contents?pagination[pageSize]=8&sort=Order:ASC&locale=${
      filter !== null ? filter : langFromLocalStorage
    }`
  ).then((res) => res.json());
};

export const Update_Bar_Contents = async ({ queryKey }) => {
  const { filter } = queryKey[1];
  const selectedLanguage = handleLang();
  const langFromLocalStorage = IsLocalStorageEmptyForApiCall(selectedLanguage);
  return fetch(
    `${Config.BaseUrl}/update-bars?pagination[pageSize]=1&locale=${
      filter !== null ? filter : langFromLocalStorage
    }`
  ).then((res) => res.json());
};

export const Update_galleries = async ({ queryKey }) => {
  const { pageParam } = queryKey[1];
  return fetch(
    `${Config.BaseUrl}/home-page-galleries?pagination[page]=${pageParam}&pagination[pageSize]=3&populate[img][fields][0]=name&populate[img][fields][1]=url&fields[0]=url&fields[1]=rawImageUrl&sort=order:ASC`
  ).then((res) => res.json());
};

export const prayerOfTheDay = async ({ queryKey }) => {
  const { pageParam } = queryKey[1];
  return fetch(
    `${Config.BaseUrl}/topic-contents?pagination[pageSize]=1&pagination[page]=${pageParam}&filters[topic_categories][URL][$eq]=daily-prayers-hindu-prayer-hub&sort=Order:ASC`
  ).then((res) => res.json());
};

export const featurePrayerOfTheDay = async () => {
  return fetch(
    `${Config.BaseUrl}/featured-prayers?pagination[pageSize]=1`
  ).then((res) => res.json());
};
